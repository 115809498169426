import i18n from 'helpers/i18n';
import messages from './messages';
import { clsx } from 'clsx';
import { useState, useMemo, useEffect } from 'react';

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption
} from '@headlessui/react';

export type NodeOption = {
  /** Node Id. */
  id: number;

  /** Node Name. */
  name: string;

  /** Ids of the node parent(s). */
  treeIds: number[];
};

interface HierarchySearchProps {
  /** List of node options. */
  nodes: NodeOption[];

  /** Callback fired when a combobox option is selected. */
  onNodeSelect: (node: NodeOption) => void;
}

export function HierarchySearch({ nodes, onNodeSelect }: HierarchySearchProps) {
  const [selectedNode, setSelectedNode] = useState<NodeOption | null>(null);
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300);

    return () => clearTimeout(handler);
  }, [query]);

  const filteredNodes = useMemo(() => {
    if (!debouncedQuery) return nodes.slice(0, 100); // Show first 100 by default

    return nodes
      .filter(node =>
        node.name.toLowerCase().includes(debouncedQuery.toLowerCase())
      )
      .slice(0, 200); // Limit to maximum 200 results
  }, [nodes, debouncedQuery]);

  function handleNodeSelect(node: NodeOption) {
    setSelectedNode(node);
    onNodeSelect(node);
  }

  function formatNode(name: string) {
    const matchIdx = name.toLowerCase().indexOf(debouncedQuery.toLowerCase());

    if (matchIdx === -1) {
      return name;
    }

    return (
      <span>
        {name.slice(0, matchIdx)}
        <span className="font-semibold">
          {name.slice(matchIdx, matchIdx + debouncedQuery.length)}
        </span>
        {name.slice(matchIdx + debouncedQuery.length)}
      </span>
    );
  }

  return (
    <Combobox value={selectedNode} onChange={handleNodeSelect}>
      <div className="relative">
        <ComboboxInput
          aria-label={i18n.ft(messages.hierarchySearch.placeholder)}
          placeholder={i18n.ft(messages.hierarchySearch.placeholder)}
          className="w-full font-sans text-base border border-[#889EBB] rounded-lg p-2 pr-8 placeholder:text-[#3C3F42] data-[focus]:outline-0 data-[focus]:border-[#0A9CCA] data-[focus]:ring-1 data-[focus]:ring-[#0A9CCA] data-[open]:rounded-b-none"
          displayValue={(node: NodeOption) => node?.name}
          onChange={event => setQuery(event.target.value)}
        />
        <div className="absolute right-0 inset-y-0 flex items-center pr-2.5">
          <i className="fa-solid fa-magnifying-glass" />
        </div>
      </div>

      <ComboboxOptions className="border-x border-b border-[#0A9CCA] ring-1 ring-[#0A9CCA] rounded-b-lg max-h-40 empty:invisible overflow-auto">
        {filteredNodes.map((node, idx) => (
          <ComboboxOption
            key={node.id}
            value={node}
            className={clsx(
              'font-sans p-2 bg-white data-[focus]:bg-[#F7F8F9]',
              idx === nodes.length - 1 && 'rounded-b-lg'
            )}
          >
            {formatNode(node.name)}
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  );
}
